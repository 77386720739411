(function ($, generic) {
  $(document).on('product:social_share:overlay', function (event, data) {
    Drupal.behaviors.productSocialShareOverlayV1.launch(data);
  });

  Drupal.behaviors.productSocialShareOverlayV1 = {
    launch: function (data) {
      var that = this;
      var rendered = site.template.get({
        name: 'product_social_share_overlay',
        data: data
      });

      generic.overlay.launch({
        content: rendered,
        cssStyle: {
          width: '635px',
          height: '550px'
        },
        onComplete: function (response) {
          var $overlayCont = $('.email_friend-form__container');
          var $formCont = $('.js-email_friend-form', $overlayCont);
          var $productID = $('.js-email_friend-product_id', $overlayCont);
          var $submit = $('.js-email_friend-submit', $overlayCont);
          var $successCont = $('.js-email_friend-success', $overlayCont);
          var $errorList = $('.js-error-messages', $overlayCont);

          // Set Product ID
          $productID.val(data.product_id);

          $formCont.bind('submit', function (e) {
            e.preventDefault();
            var pObj = {};
            var params = $(this).serializeArray();

            for (var i = 0, len = params.length; i < len; i++) {
              pObj[params[i].name] = params[i].value;
            }
            generic.jsonrpc.fetch({
              method: 'form.get',
              params: [pObj],
              onSuccess: function (r) {
                $formCont.hide();
                $successCont.show();
                $.colorbox.resize();
              },
              onFailure: function (r) {
                var errorObj = r.getError();
                var errorArray = errorObj.data.messages;

                generic.showErrors(errorArray, $errorList);
              }
            });
          });
        }
      });
    }
  };
})(jQuery, generic);
